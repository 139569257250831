import * as React from "react"
import { SVGProps } from "react"

const WarehouseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<path
			d="M11.9999 2L2.74609 6.02984L11.9999 10.3209"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0001 2L21.2539 6.02984L12.0001 10.3209"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.3184 3.88086L7.21246 8.10333V12.2886"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.74609 6.32812V17.7087L11.9999 21.9998"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21.2539 6.2168L21.2539 17.7093L12.0001 22.0004"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.9922 21.7669V10.5156"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default WarehouseIcon
