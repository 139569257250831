import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/terminal.module.scss'

import ProductPresentation from '../pagesSections/terminal/ProductPresentation'
import Features from '../pagesSections/terminal/Features'
import { getTerminalMeta } from '../pagesSections/terminal/meta'

import Advantages from '../components/_V2/Advantages'
import BusinessTypes from '../components/_V2/BusinessTypes'

import { advantagesSlides, getAdvantagesSlides } from '../pages-data/_V2/terminal/advantages'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import CtaForm from '../pagesSections/index/CtaForm'
import TerminalTools from '../pagesSections/terminal/TerminalTools'
import navStyles from '../components/_V2/Header/components/NavigationDesktop/styles.module.scss'
import { HEADER_THEMES } from '../components/_V2/Header'
import { useLocalizationContext } from '../localization/useLocalizationContext'
import {getSeoText} from "../pages-data/_V2/terminal/seotext";
import SeoText from "../components/_V2/SeoText";

export default function PageTerminal() {
  const localizationContext = useLocalizationContext()

  const metaInfo = getTerminalMeta(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  navStyles.pageWrapper_background_blue,
      )}
      headerTheme={HEADER_THEMES.DEFAULT}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        items={getAdvantagesSlides(localizationContext.locale)}
        hideImages={false}
        className={classNames(styles.terminal__advantages, pageStyles.pageSection)}
      />

      <Features className={classNames(styles.terminal__features)} />

      <TerminalTools className={pageStyles.pageSection} />

      <CtaForm className={pageStyles.pageSection} isFry={false} />

      <BusinessTypes
        key={localizationContext.locale}
        data={businessTypes(localizationContext.locale)}
        className={classNames(
				  pageStyles.pageSection,
				  styles.terminal__business,
        )}
      />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
      <SeoText
          className={pageStyles.pageSection}
          data={getSeoText()}
      />
    </DefaultLayout>
  )
}
