import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import classNames from "classnames"
import TablesIcon from "./assets/TablesIcon"
import PayIcon from "./assets/PayIcon"
import WarehouseIcon from "./assets/WarehouseIcon"
import DishSortIcon from "./assets/DishSortIcon"
import AccessIcon from "./assets/AccessIcon"
import CheckIcon from "./assets/CheckIcon"
import ConnectIcon from "./assets/ConnectIcon"

import styles from "./styles.module.scss"
import NoteIcon from "./assets/NoteIcon"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			category: "инструменты",
			title: "Ещё быстрее и проще",
			headerPlacement: "top",
			features: [
				{
					icon: <TablesIcon />,
					name: "Схемы столов",
					label:
						"Кассиры легко смогут ориентироваться в заказах и точно не забудут про гостей.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-1_kz.png"
							alt="схема столов в кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description170,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
							data-top="center"
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Кассиры
								<br />
								легко&nbsp;смогут
								<br />
								ориентироваться
								<br />
								в&nbsp;заказах и точно
								<br />
								не&nbsp;забудут про
								<br />
								гостей.
							</div>
						</div>
					),
				},
				{
					icon: <PayIcon />,
					name: "Все типы оплаты",
					descriptionSize: "default",
					label:
						"Легко разделить чек по гостям и способам оплаты — картами, наличными или бонусами.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-2_kz.png"
							alt="варианты оплат на кассе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description165,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
							data-top="10px"
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Легко разделить чек&nbsp;по&nbsp;гостям и&nbsp;способам&nbsp;оплаты
								— картами, наличными или&nbsp;бонусами.
							</div>
						</div>
					),
				},
				{
					icon: <WarehouseIcon />,
					name: "Склад и инвентаризация",
					label:
						"Остатки в реальном времени, приходные накладные и управление поставками.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-3_kz.png"
							alt="складские документы для ресторана"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description170,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Остатки
								<br />
								в&nbsp;реальном
								<br />
								времени, приходные
								<br />
								накладные и&nbsp;управление поставками.
							</div>
						</div>
					),
				},
				{
					icon: <DishSortIcon />,
					name: "Очередность подачи блюд",
					label:
						"По готовности или все сразу. А может гость хочет начать с десерта? Оповести кухню и официантов заранее.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-4_kz.png"
							alt="очередность подачи блюд"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description182,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								По готовности или&nbsp;все&nbsp;сразу. А может гость хочет начать
								с&nbsp;десерта? Оповести кухню и официатов заранее.
							</div>
						</div>
					),
				},
				{
					icon: <AccessIcon />,
					name: "Разграничение прав доступа",
					label:
						"Доверяй, но проверяй. Ограничь список тех, кому можно доверить кассу полностью.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-5_kz.png"
							alt="права доступа на кассе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description165,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Доверяй,
								<br />
								но&nbsp;проверяй. Ограничь
								<br />
								список&nbsp;тех, кому&nbsp;можно
								<br />
								доверить&nbsp;кассу
								<br />
								полностью.
							</div>
						</div>
					),
				},
				{
					icon: <CheckIcon />,
					name: "Брендированный чек",
					label: "Лишний повод показать, какой крутой лого у твоего проекта!",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-6_kz.png"
							alt="кассовый чек в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
							height={438}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description150,
								styles.terminalToolsAbout__descriptionCheck
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Лишний повод&nbsp;показать,&nbsp;какой крутой&nbsp;лого
								у&nbsp;твоего проекта!
							</div>
						</div>
					),
				},
				{
					icon: <ConnectIcon />,
					name: "Мгновенная связь с кухней",
					label:
						"Теперь не надо бегать на кухню или делать пометки на чеках.  Уточнить статус заказа можно также с терминала.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-7_kz.png"
							alt="передача заказа на кухню"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description182,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Теперь не надо бегать на кухню или делать пометки на чеках. Уточнить
								статус заказа можно также с&nbsp;терминала.
							</div>
						</div>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			category: "рестораторлар мен",
			title: "Одан да тезірек және оңайырақ",
			headerPlacement: "top",
			features: [
				{
					icon: <TablesIcon />,
					name: "Үстелдер сұлбалары",
					label:
						"Кассирлер тапсырыстарда оңай бейімделіп, қонақтарын ұмытпайтындары сөзсіз.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-1_kz.png"
							alt="схема столов в кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description170,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
							data-top="center"
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Кассирлер
								<br />
								тапсырыстарда
								<br />
								оңай бейімделіп,
								<br />
								қонақтарын
								<br />
								ұмытпайтындары
								<br />
								сөзсіз.
							</div>
						</div>
					),
				},
				{
					icon: <PayIcon />,
					name: "Барлық төлем түрлері",
					descriptionSize: "default",
					label:
						"Чекті қонақтар және төлем тәсілдері бойынша бөлу оңай — картамен, қолма-қол ақшамен немесе бонустармен.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-2_kz.png"
							alt="варианты оплат на кассе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description165,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
							data-top="10px"
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Чекті қонақтар және <br />төлем тәсілдері бойынша бөлу оңай — картамен, қолма-қол ақшамен <br /> немесе бонустармен.
							</div>
						</div>
					),
				},
				{
					icon: <WarehouseIcon />,
					name: "Қойма және түгендеу",
					label:
						"Нақты уақытта қалдықтар, кіріс жүкқұжаттары және жеткізуді басқару.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-3_kz.png"
							alt="складские документы для ресторана"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description170,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Нақты
								<br />
								уақытта қалдықтар,
								<br />
								кіріс жүкқұжаттары
								<br />
								және жеткізуді басқару.
							</div>
						</div>
					),
				},
				{
					icon: <DishSortIcon />,
					name: "Тағамдарды беру кезектігі",
					label:
						"Дайын болғанына қарай немесе бәрі бір уақытта. Ал қонақ десерттен бастағысы келсе ше? Асүйге және даяшыларға алдын ала хабарлаңыз.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-4_kz.png"
							alt="очередность подачи блюд"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description182,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Дайын болғанына қарай немесе бәрі бір уақытта. Ал қонақ десерттен бастағысы келсе ше? Асүйге және даяшыларға алдын ала хабарлаңыз.
							</div>
						</div>
					),
				},
				{
					icon: <AccessIcon />,
					name: "Қолжеткізу құқықтарын шектеу",
					label:
						"Сеніңіз, бірақ тексеріңіз. Кассаны толықтай сеніп тапсыруға болатын адамдардың тізімін шектеңіз.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-5_kz.png"
							alt="права доступа на кассе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description165,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Сеніңіз,
								<br />
								бірақ тексеріңіз.
								<br />
								Кассаны толықтай сеніп
								<br />
								тапсыруға болатын адамдардың
								<br />
								тізімін шектеңіз.
							</div>
						</div>
					),
				},
				{
					icon: <CheckIcon />,
					name: "Брендтелген чек",
					label: "Сіздің жобаңызда қандай күшті лого екенін көрсетуге артық себеп!",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-6_kz.png"
							alt="кассовый чек в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
							height={438}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description150,
								styles.terminalToolsAbout__descriptionCheck
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Сіздің жобаңызда қандай күшті лого екенін көрсетуге артық себеп!
							</div>
						</div>
					),
				},
				{
					icon: <ConnectIcon />,
					name: "Асаүймен лезде байланысу",
					label:
						"Енді асүйге жүгірудің немесе чектерде белгілер жасаудың керегі жоқ.  Тапсырыс мәртебесін терминалдан да нақтылауға болады.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-7_kz.png"
							alt="передача заказа на кухню"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description182,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Енді асүйге жүгірудің немесе чектерде белгілер жасаудың керегі жоқ. Тапсырыс мәртебесін терминалдан да нақтылауға болады.
							</div>
						</div>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			category: "инструменты",
			title: "Ещё быстрее и проще",
			headerPlacement: "top",
			features: [
				{
					icon: <TablesIcon />,
					name: "Схемы столов",
					label:
						"Кассиры легко смогут ориентироваться в заказах и точно не забудут про гостей.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-1_by.png"
							alt="схема столов в кафе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description170,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
							data-top="center"
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Кассиры
								<br />
								легко&nbsp;смогут
								<br />
								ориентироваться
								<br />
								в&nbsp;заказах и точно
								<br />
								не&nbsp;забудут про
								<br />
								гостей.
							</div>
						</div>
					),
				},
				{
					icon: <PayIcon />,
					name: "Все типы оплаты",
					descriptionSize: "default",
					label:
						"Легко разделить чек по гостям и способам оплаты — картами, наличными или бонусами.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-2_by.png"
							alt="варианты оплат на кассе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description165,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
							data-top="10px"
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Легко разделить чек&nbsp;по&nbsp;гостям и&nbsp;способам&nbsp;оплаты
								— картами, наличными или&nbsp;бонусами.
							</div>
						</div>
					),
				},
				{
					icon: <WarehouseIcon />,
					name: "Склад и инвентаризация",
					label:
						"Остатки в реальном времени, приходные накладные и управление поставками.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-3_by.png"
							alt="складские документы для ресторана"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description170,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Остатки
								<br />
								в&nbsp;реальном
								<br />
								времени, приходные
								<br />
								накладные и&nbsp;управление поставками.
							</div>
						</div>
					),
				},
				{
					icon: <DishSortIcon />,
					name: "Очередность подачи блюд",
					label:
						"По готовности или все сразу. А может гость хочет начать с десерта? Оповести кухню и официантов заранее.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-4_by.png"
							alt="очередность подачи блюд"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description182,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								По готовности или&nbsp;все&nbsp;сразу. А может гость хочет начать
								с&nbsp;десерта? Оповести кухню и официатов заранее.
							</div>
						</div>
					),
				},
				{
					icon: <AccessIcon />,
					name: "Разграничение прав доступа",
					label:
						"Доверяй, но проверяй. Ограничь список тех, кому можно доверить кассу полностью.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-5_by.png"
							alt="права доступа на кассе"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description165,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Доверяй,
								<br />
								но&nbsp;проверяй. Ограничь
								<br />
								список&nbsp;тех, кому&nbsp;можно
								<br />
								доверить&nbsp;кассу
								<br />
								полностью.
							</div>
						</div>
					),
				},
				{
					icon: <CheckIcon />,
					name: "Брендированный чек",
					label: "Лишний повод показать, какой крутой лого у твоего проекта!",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-6_by.png"
							alt="кассовый чек в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
							height={438}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description150,
								styles.terminalToolsAbout__descriptionCheck
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Лишний повод&nbsp;показать,&nbsp;какой крутой&nbsp;лого
								у&nbsp;твоего проекта!
							</div>
						</div>
					),
				},
				{
					icon: <ConnectIcon />,
					name: "Мгновенная связь с кухней",
					label:
						"Теперь не надо бегать на кухню или делать пометки на чеках.  Уточнить статус заказа можно также с терминала.",
					img: (
						<StaticImage
							className={styles.terminalTools__image}
							src="./assets/tools-7_by.png"
							alt="передача заказа на кухню"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					badge: (
						<div
							className={classNames(
								styles.terminalToolsAbout__description,
								styles.terminalToolsAbout__description182,
								styles.terminalToolsAbout__descriptionRightCenter
							)}
						>
							<div className={styles.terminalToolsAbout__descriptionContent}>
								<NoteIcon />
								Теперь не надо бегать на кухню или делать пометки на чеках. Уточнить
								статус заказа можно также с&nbsp;терминала.
							</div>
						</div>
					),
				},
			],
		}
	}

	return {
		category: "создан для всех типов бизнеса",
		title: "Ещё быстрее и проще",
		headerPlacement: "top",
		features: [
			{
				icon: <TablesIcon />,
				name: "Схемы столов",
				label:
					"Кассиры легко смогут ориентироваться в заказах и точно не забудут про гостей.",
				img: (
					<StaticImage
						className={styles.terminalTools__image}
						src="./assets/tools-1.png"
						alt="схема столов в кафе"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.terminalToolsAbout__description,
							styles.terminalToolsAbout__description170,
							styles.terminalToolsAbout__descriptionRightCenter
						)}
						data-top="center"
					>
						<div className={styles.terminalToolsAbout__descriptionContent}>
							<NoteIcon />
							Кассиры
							<br />
							легко&nbsp;смогут
							<br />
							ориентироваться
							<br />
							в&nbsp;заказах и точно
							<br />
							не&nbsp;забудут про
							<br />
							гостей.
						</div>
					</div>
				),
			},
			{
				icon: <PayIcon />,
				name: "Все типы оплаты",
				descriptionSize: "default",
				label:
					"Легко разделить чек по гостям и способам оплаты — картами, наличными или бонусами.",
				img: (
					<StaticImage
						className={styles.terminalTools__image}
						src="./assets/tools-2.png"
						alt="варианты оплат на кассе"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.terminalToolsAbout__description,
							styles.terminalToolsAbout__description165,
							styles.terminalToolsAbout__descriptionRightCenter
						)}
						data-top="10px"
					>
						<div className={styles.terminalToolsAbout__descriptionContent}>
							<NoteIcon />
							Легко разделить чек&nbsp;по&nbsp;гостям и&nbsp;способам&nbsp;оплаты
							— картами, наличными или&nbsp;бонусами.
						</div>
					</div>
				),
			},
			{
				icon: <WarehouseIcon />,
				name: "Склад и инвентаризация",
				label:
					"Остатки в реальном времени, приходные накладные и управление поставками.",
				img: (
					<StaticImage
						className={styles.terminalTools__image}
						src="./assets/tools-3.png"
						alt="складские документы для ресторана"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.terminalToolsAbout__description,
							styles.terminalToolsAbout__description170,
							styles.terminalToolsAbout__descriptionRightCenter
						)}
					>
						<div className={styles.terminalToolsAbout__descriptionContent}>
							<NoteIcon />
							Остатки
							<br />
							в&nbsp;реальном
							<br />
							времени, приходные
							<br />
							накладные и&nbsp;управление поставками.
						</div>
					</div>
				),
			},
			{
				icon: <DishSortIcon />,
				name: "Очередность подачи блюд",
				label:
					"По готовности или все сразу. А может гость хочет начать с десерта? Оповести кухню и официантов заранее.",
				img: (
					<StaticImage
						className={styles.terminalTools__image}
						src="./assets/tools-4.png"
						alt="очередность подачи блюд"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.terminalToolsAbout__description,
							styles.terminalToolsAbout__description182,
							styles.terminalToolsAbout__descriptionRightCenter
						)}
					>
						<div className={styles.terminalToolsAbout__descriptionContent}>
							<NoteIcon />
							По готовности или&nbsp;все&nbsp;сразу. А может гость хочет начать
							с&nbsp;десерта? Оповести кухню и официатов заранее
						</div>
					</div>
				),
			},
			{
				icon: <AccessIcon />,
				name: "Разграничение прав доступа",
				label:
					"Доверяй, но проверяй. Ограничь список тех, кому можно доверить кассу полностью.",
				img: (
					<StaticImage
						className={styles.terminalTools__image}
						src="./assets/tools-5.png"
						alt="права доступа на кассе"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.terminalToolsAbout__description,
							styles.terminalToolsAbout__description165,
							styles.terminalToolsAbout__descriptionRightCenter
						)}
					>
						<div className={styles.terminalToolsAbout__descriptionContent}>
							<NoteIcon />
							Доверяй,
							<br />
							но&nbsp;проверяй. Ограничь
							<br />
							список&nbsp;тех, кому&nbsp;можно
							<br />
							доверить&nbsp;кассу
							<br />
							полностью
						</div>
					</div>
				),
			},
			{
				icon: <CheckIcon />,
				name: "Брендированный чек",
				label: "Лишний повод показать, какой крутой лого у твоего проекта!",
				img: (
					<StaticImage
						className={styles.terminalTools__image}
						src="./assets/tools-6.png"
						alt="кассовый чек в ресторане"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
						height={438}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.terminalToolsAbout__description,
							styles.terminalToolsAbout__description150,
							styles.terminalToolsAbout__descriptionCheck
						)}
					>
						<div className={styles.terminalToolsAbout__descriptionContent}>
							<NoteIcon />
							Лишний повод&nbsp;показать,&nbsp;какой крутой&nbsp;лого
							у&nbsp;твоего проекта!
						</div>
					</div>
				),
			},
			{
				icon: <ConnectIcon />,
				name: "Мгновенная связь с кухней",
				label:
					"Теперь не надо бегать на кухню или делать пометки на чеках.  Уточнить статус заказа можно также с терминала.",
				img: (
					<StaticImage
						className={styles.terminalTools__image}
						src="./assets/tools-7.png"
						alt="передача заказа на кухню"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				badge: (
					<div
						className={classNames(
							styles.terminalToolsAbout__description,
							styles.terminalToolsAbout__description182,
							styles.terminalToolsAbout__descriptionRightCenter
						)}
					>
						<div className={styles.terminalToolsAbout__descriptionContent}>
							<NoteIcon />
							Теперь не надо бегать на кухню или делать пометки на чеках. Уточнить
							статус заказа можно также с&nbsp;терминала.
						</div>
					</div>
				),
			},
		],
	}
}
