import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			pageName: "Кассовый терминал",
			desktopTitle: "Сотни задач. Один интерфейс.",
			subTitle: (
				<div className={styles.subTitle}>
					Заказы из зала и на доставку. Все виды оплат. Управление кухней и складом.
					Единый кассовый терминал, который может и больше!
				</div>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			tabletCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.terminalImage}
					src="./assets/terminal-presintation_kz.png"
					alt="Кассовый терминал Quick Resto"
					breakpoints={[337, 674, 870, 1740, 821, 1642]}
					sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 600px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			bottomCtaDetails: "Быстро, удобно и две недели бесплатно."
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Кассалық терминал",
			desktopTitle: "Жүздеген міндеттер.  Бір интерфейс.",
			subTitle: (
				<div className={styles.subTitle}>
					Залдан және жеткізуге тапсырыстар. Барлық төлем түрлері.
					 Асүйді және қойманы басқару. Мүмкіндіктері көп бірыңғай кассалық терминал!
				</div>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			tabletCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.terminalImage}
					src="./assets/terminal-presintation_kz.png"
					alt="Кассовый терминал Quick Resto"
					breakpoints={[337, 674, 870, 1740, 821, 1642]}
					sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 600px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			bottomCtaDetails: "Тез, ыңғайлы және екі апта тегін."
		}
	}

	if (locale === 'ru-BY') {
		return {
			pageName: "Кассовый терминал",
			desktopTitle: "Сотни задач. Один интерфейс.",
			subTitle: (
				<div className={styles.subTitle}>
					Заказы из зала и на доставку. Все виды оплат. Управление кухней и складом.
					Единый кассовый терминал, который может и больше!
				</div>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			tabletCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			mobileCtaDetails:(
				<div className={styles.stores}>
					<StaticImage
						className={styles.storeImage}
						src="./assets/apple.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="App Store"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/playmarket.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Play Market"
					/>
					<StaticImage
						className={styles.storeImage}
						src="./assets/windows.png"
						width={38}
						objectFit="contain"
						placeholder="blurred"
						alt="Microsoft Store"
					/>
				</div>
			),
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.terminalImage}
					src="./assets/terminal-presintation_by.png"
					alt="Кассовый терминал Quick Resto"
					breakpoints={[337, 674, 870, 1740, 821, 1642]}
					sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 600px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			bottomCtaDetails: "Быстро, удобно и две недели бесплатно."
		}
	}

	return {
		pageName: "Кассовый терминал",
		desktopTitle: "Сотни задач. Один интерфейс.",
		subTitle: (
			<div className={styles.subTitle}>
				Заказы из зала и на доставку. Все виды оплат. Управление кухней и складом.
				Единый кассовый терминал, который может и больше!
			</div>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails:(
			<div className={styles.stores}>
				<StaticImage
					className={styles.storeImage}
					src="./assets/apple.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="App Store"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/playmarket.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Play Market"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/windows.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Microsoft Store"
				/>
			</div>
		),
		tabletCtaDetails:(
			<div className={styles.stores}>
				<StaticImage
					className={styles.storeImage}
					src="./assets/apple.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="App Store"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/playmarket.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Play Market"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/windows.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Microsoft Store"
				/>
			</div>
		),
		mobileCtaDetails:(
			<div className={styles.stores}>
				<StaticImage
					className={styles.storeImage}
					src="./assets/apple.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="App Store"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/playmarket.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Play Market"
				/>
				<StaticImage
					className={styles.storeImage}
					src="./assets/windows.png"
					width={38}
					objectFit="contain"
					placeholder="blurred"
					alt="Microsoft Store"
				/>
			</div>
		),
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				className={styles.terminalImage}
				src="./assets/terminal-presintation.png"
				alt="Кассовый терминал Quick Resto"
				breakpoints={[337, 674, 870, 1740, 821, 1642]}
				sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 600px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		bottomCtaDetails:
			"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
	}
}
