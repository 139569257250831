import React from "react"

import ProductPresentationBase from "../../../components/_V2/ProductPresentationBase"

import Backdrop from "./assets/Backdrop"
import BackdropMobile from "./assets/BackdropMobile"

import { getData } from "./data"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";
import classNames from "classnames";

interface Props {
	className?: string
}

export default function ProductPresentation(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	return (
		<ProductPresentationBase
			key={localizationContext.locale}
			data={data}
			backdrop={Backdrop}
			backdropMobile={BackdropMobile}
			backdropClass={styles.backdrop}
			backdropMobileClass={styles.backdropMobile}
			className={props.className}
			containerClass={styles.content}
			sectionClass={styles.section}
			additionalRightColumn={styles.rightColumn}
			adaptiveWrapTextClassName={classNames({[styles.subTitle_terminal]: localizationContext.locale !== 'ru-RU'})}
		/>
	)
}
