import * as React from "react"
import { SVGProps } from "react"

const Backdrop = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1377"
		height="558"
		viewBox="0 0 1377 558"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			d="M355.994 449.543L7.60482 51.271C-5.24305 36.5835 -0.49835 13.6867 17.128 5.31418C20.3492 3.78414 23.8216 2.8523 27.376 2.56411L59 0L1162.04 21.3319C1246.31 22.9617 1322.13 72.929 1356.86 149.729C1404.63 255.36 1361.57 379.909 1258.75 433.476L1073.03 530.245C1034.4 550.369 991.034 559.603 947.563 556.96L522.067 531.085C458.037 527.191 398.23 497.826 355.994 449.543Z"
			fill="url(#paint0_linear_2_2)"
		/>
		<path
			d="M355.994 449.543L7.60482 51.271C-5.24305 36.5835 -0.49835 13.6867 17.128 5.31418C20.3492 3.78414 23.8216 2.8523 27.376 2.56411L59 0L1162.04 21.3319C1246.31 22.9617 1322.13 72.929 1356.86 149.729C1404.63 255.36 1361.57 379.909 1258.75 433.476L1073.03 530.245C1034.4 550.369 991.034 559.603 947.563 556.96L522.067 531.085C458.037 527.191 398.23 497.826 355.994 449.543Z"
			fill="url(#pattern0)"
			fillOpacity="0.1"
		/>
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width="0.0136986"
				height="0.0365419"
			>
				<use
					xlinkHref="#image0_2_2"
					transform="scale(0.000342466 0.000891266)"
				/>
			</pattern>
			<linearGradient
				id="paint0_linear_2_2"
				x1="1310"
				y1="902"
				x2="78.6237"
				y2="691.091"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#3A88FD" />
				<stop offset="0.457622" stopColor="#977DFF" />
				<stop offset="1" stopColor="#FF84F3" />
			</linearGradient>
			<image
				id="image0_2_2"
				width="40"
				height="41"
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAf0lEQVRYCe3SwQnAIBBE0ek1ahuxg9iLbFKWJSRIPAi5eBIhX1jw4OL4XImFAAIIIIAAAggggAACiwgEXbuTlVpB57FIrDdGDeRld19BFpcJ6WWlD9f2hYCjAk4Wv4I5jfZPObcpp/bVxSmvM39TXs8lCCCAAAIIIIAAAr8UeAAiHz7ihAEDdwAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)

export default Backdrop
