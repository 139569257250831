import * as React from "react"
import { SVGProps } from "react"

const TablesIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<circle
			cx="6.6776"
			cy="17.3221"
			r="3.5233"
			stroke="white"
			strokeWidth="2"
		/>
		<circle
			cx="17.3221"
			cy="17.3221"
			r="3.5233"
			stroke="white"
			strokeWidth="2"
		/>
		<circle cx="6.6776" cy="6.6776" r="3.5233" stroke="white" strokeWidth="2" />
		<circle
			cx="17.3221"
			cy="6.6776"
			r="3.5233"
			stroke="white"
			strokeWidth="2"
		/>
	</svg>
)

export default TablesIcon
