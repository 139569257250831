import React from "react"

import TabsFeatures, { HIDE_BADGES } from "../../../components/_V2/TabsFeatures"

import { getData } from "./data"
import styles from "./styles.module.scss"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function TerminalTools(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<TabsFeatures
			key={localizationContext.locale}
			data={getData(localizationContext.locale)}
			rootClass={styles.terminalTools}
			containerClass={styles.terminalTools__container}
			hideBadges={HIDE_BADGES.MOBILE}
			wrapperClass={styles.terminalTools__wrapper}
			isFullWidth
			theme={"fuchsia"}
		/>
	)
}
