import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {Locales} from "../../../localization/types";

export const getAdvantagesSlides = (locale :Locales) => {

	if (locale === 'kz-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/t-advantages-1.png"
						alt="Работает на всех устройствах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Барлық құрылғыларда жұмыс істейді",
				description:
					"Планшет, ноутбук, моноблок — жұмыс істеу үйреншікті болатын барлық құрылғыларда.",
			},
			{
				img: (
					<StaticImage
						src="./assets/t-advantages-2.png"
						alt="Касса, которая не тормозит"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Тежемейтін касса",
				description:
					"Интернетті өшірді ме? Жарық жоғалды ма? Касса автономды түрде жұмыс істей береді.",
			},
			{
				img: (
					<StaticImage
						src="./assets/t-advantages-3.png"
						alt="Тут всё понятно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Мұнда бәрі түсінікті",
				description:
					"Персоналды табу қиын, бәрін үйрету — ұзақ. Түсінікті интерфейс неғұрлым маңызды істер үшін уақытты үнемдейді.",
			},
		]
	}

	return [
		{
			img: (
				<StaticImage
					src="./assets/t-advantages-1.png"
					alt="Работает на всех устройствах"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Работает на всех устройствах",
			description:
				"Планшет, ноутбук, моноблок — на всем, с чем привычно работать.",
		},
		{
			img: (
				<StaticImage
					src="./assets/t-advantages-2.png"
					alt="Касса, которая не тормозит"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Касса, которая не тормозит",
			description:
				"Отключили интернет? Пропал свет? Касса продолжит работать автономно.  ",
		},
		{
			img: (
				<StaticImage
					src="./assets/t-advantages-3.png"
					alt="Тут всё понятно"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Тут всё понятно",
			description:
				"Найти персонал трудно, научить всему — долго. Понятный интерфейс сэкономит время для более важных дел.",
		},
	]
}

export const advantagesSlides = [
	{
		img: (
			<StaticImage
				src="./assets/t-advantages-1.png"
				alt="Работает на всех устройствах"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		title: "Работает на всех устройствах",
		description:
			"Планшет, ноутбук, моноблок — на всем, с чем привычно работать.",
	},
	{
		img: (
			<StaticImage
				src="./assets/t-advantages-2.png"
				alt="Касса, которая не тормозит"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		title: "Касса, которая не тормозит",
		description:
			"Отключили интернет? Пропал свет? Касса продолжит работать автономно.  ",
	},
	{
		img: (
			<StaticImage
				src="./assets/t-advantages-3.png"
				alt="Тут всё понятно"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		title: "Тут всё понятно",
		description:
			"Найти персонал трудно, научить всему — долго. Понятный интерфейс сэкономит время для более важных дел.",
	},
]
