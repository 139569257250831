import * as React from "react"
import { SVGProps } from "react"

const DishSortIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<path
			d="M21 11.0623C21 8.242 21 6.82937 20.4493 5.75143C19.9677 4.80253 19.1984 4.03063 18.2516 3.54635C17.1748 3 15.7637 3 12.9416 3H11.0536C8.23629 3 6.82521 3 5.74845 3.54635C4.80024 4.03034 4.02925 4.80218 3.54578 5.75143C3.00003 6.82937 3.00003 8.242 3.00003 11.0623V12.9377C3.00003 15.758 3.00003 17.1706 3.54578 18.2486C4.02925 19.1978 4.80024 19.9697 5.74845 20.4537C6.82521 21 8.23629 21 11.0536 21H12.9219C15.7441 21 17.1552 21 18.2319 20.4537C19.1787 19.9694 19.948 19.1975 20.4297 18.2486C20.9803 17.1706 20.9803 15.758 20.9803 12.9377L21 11.0623Z"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9 15.002V9.00195"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M15 15.002V9.00195"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M12.8787 9.12327L15 7.00195L17.1213 9.12327"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.1213 14.8767L9 16.998L6.87868 14.8767"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default DishSortIcon
