import * as React from "react"
import { SVGProps } from "react"

const ConnectIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<path
			d="M6 9.72559H7C7 9.1733 6.55228 8.72559 6 8.72559V9.72559ZM6 15.7256V16.7256C6.55228 16.7256 7 16.2779 7 15.7256H6ZM4 10.7256H6V8.72559H4V10.7256ZM5 9.72559V15.7256H7V9.72559H5ZM6 14.7256H4V16.7256H6V14.7256ZM3 13.7256V11.7256H1V13.7256H3ZM4 14.7256C3.44772 14.7256 3 14.2779 3 13.7256H1C1 15.3824 2.34315 16.7256 4 16.7256V14.7256ZM4 8.72559C2.34315 8.72559 1 10.0687 1 11.7256H3C3 11.1733 3.44772 10.7256 4 10.7256V8.72559Z"
			fill="white"
		/>
		<path
			d="M18 15.7256L17 15.7256C17 16.2779 17.4477 16.7256 18 16.7256L18 15.7256ZM18 9.72559L18 8.72559C17.4477 8.72559 17 9.1733 17 9.72559L18 9.72559ZM20 14.7256L18 14.7256L18 16.7256L20 16.7256L20 14.7256ZM19 15.7256L19 9.72559L17 9.72559L17 15.7256L19 15.7256ZM18 10.7256L20 10.7256L20 8.72559L18 8.72559L18 10.7256ZM21 11.7256L21 13.7256L23 13.7256L23 11.7256L21 11.7256ZM20 10.7256C20.5523 10.7256 21 11.1733 21 11.7256L23 11.7256C23 10.0687 21.6569 8.72559 20 8.72559L20 10.7256ZM20 16.7256C21.6569 16.7256 23 15.3824 23 13.7256L21 13.7256C21 14.2779 20.5523 14.7256 20 14.7256L20 16.7256Z"
			fill="white"
		/>
		<path
			d="M17 14C17 14.5523 17.4477 15 18 15C18.5523 15 19 14.5523 19 14H17ZM5 14C5 14.5523 5.44772 15 6 15C6.55228 15 7 14.5523 7 14H5ZM17 8V14H19V8H17ZM7 14V8H5V14H7ZM12 3C14.7614 3 17 5.23858 17 8H19C19 4.13401 15.866 1 12 1V3ZM12 1C8.13401 1 5 4.13401 5 8H7C7 5.23858 9.23858 3 12 3V1Z"
			fill="white"
		/>
		<path
			d="M19 9.55078C19 8.9985 18.5523 8.55078 18 8.55078C17.4477 8.55078 17 8.9985 17 9.55078L19 9.55078ZM12 20.5508C11.4477 20.5508 11 20.9985 11 21.5508C11 22.1031 11.4477 22.5508 12 22.5508L12 20.5508ZM17 9.55078L17 15.5508L19 15.5508L19 9.55078L17 9.55078ZM12 22.5508C15.866 22.5508 19 19.4168 19 15.5508L17 15.5508C17 18.3122 14.7614 20.5508 12 20.5508L12 22.5508Z"
			fill="white"
		/>
		<path
			d="M13.5 12C13.5 12.5523 13.9477 13 14.5 13C15.0523 13 15.5 12.5523 15.5 12L13.5 12ZM15.5 9C15.5 8.44772 15.0523 8 14.5 8C13.9477 8 13.5 8.44772 13.5 9L15.5 9ZM15.5 12L15.5 9L13.5 9L13.5 12L15.5 12Z"
			fill="white"
		/>
		<path
			d="M8.5 12C8.5 12.5523 8.94772 13 9.5 13C10.0523 13 10.5 12.5523 10.5 12L8.5 12ZM10.5 9C10.5 8.44772 10.0523 8 9.5 8C8.94772 8 8.5 8.44772 8.5 9L10.5 9ZM10.5 12L10.5 9L8.5 9L8.5 12L10.5 12Z"
			fill="white"
		/>
		<path
			d="M9.5 15.6377C10.1746 16.1995 11.0695 16.5127 12 16.5127C12.9305 16.5127 13.8254 16.1995 14.5 15.6377"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default ConnectIcon
