import * as React from "react"
import { SVGProps } from "react"

const AccessIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<path d="M12 15V12" stroke="white" strokeWidth="2" strokeLinecap="round" />
		<path
			d="M12 3.7627L4 5.99982C3.99994 13.9693 4 18.0391 12 21.9998"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 3.7627L20 5.99982C20.0001 13.9693 20 18.0391 12 21.9998"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle cx="12" cy="10.5" r="2" fill="white" />
	</svg>
)

export default AccessIcon
