import * as React from "react"
import { SVGProps } from "react"

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<path
			d="M19 13.999C20.6569 13.999 22 15.3422 22 16.999C22 18.6559 20.6569 19.999 19 19.999H5C3.34315 19.999 2 18.6559 2 16.999C2 15.3422 3.34315 13.999 5 13.999"
			stroke="white"
			strokeWidth="2"
		/>
		<path
			d="M20 4.00391L8 4.00391C6.89543 4.00391 6 4.89934 6 6.00391L6 20.0039"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M18 20.0039L18 6.00391C18 4.89934 18.8954 4.00391 20 4.00391V4.00391C21.1046 4.00391 22 4.89934 22 6.00391L22 7.00047"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M15 13.999H9"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M15 9.99902H9"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default CheckIcon
